.breadcrumbs {
  border-radius: 10px;
  border: 1px solid var(--sl-color);
  box-shadow: 0 0 2px var(--sl-color);
  padding: 1em;
}

.breadcrumbs__item {
  display: inline-block;
}

.breadcrumbs__link {
  font-size: 1.3em;
  margin-right: 10px;
  padding-right: 20px;
  position: relative;
  text-decoration: none;
}

.breadcrumbs__link::after {
  border-right:2px solid #555;
  border-top:2px solid #555;
  bottom: 7px;
  content: "";
  display: block;
  height: 10px;
  position:absolute;
  right: 0;
  transform:rotate(45deg);
  width: 11px;
}

.breadcrumbs__item:last-of-type {
  font-weight: bold;
}

.breadcrumbs__item:last-of-type
.breadcrumbs__link::after {
  display: none;
}