.form-radio {
  border: 1px solid var(--pr-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  padding: 0;
}

.form-radio__item {
  display: flex;
  list-style: none;
}

.form-radio__label {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  flex: 1;
  text-align: center;
}

.form-radio__label_first {
  border-radius: 10px 10px 0 0;
  border-bottom: 1px solid var(--pr-color);
}

.form-radio__label_middle {
  border-bottom: 1px solid var(--pr-color);
}

.form-radio__label_last {
  border-radius: 0 0 10px 10px;
}

.form-radio__radio {
  appearance: none;
  opacity: 0;
  position: absolute;
}

.form-radio__radio:checked + 
.form-radio__label {
  background-color: var(--sl-color);
}

@media (min-width: 720px) {
  .form-radio {
    flex-direction: row;
  }
  
  .form-radio__item {
    flex: 1;
  }

  .form-radio__label_first {
    border-bottom: none;
    border-radius: 10px 0 0 10px;
    border-right: 1px solid var(--pr-color);
  }
  
  .form-radio__label_middle {
    border-bottom: none;
    border-right: 1px solid var(--pr-color);
  }
  
  .form-radio__label_last {
    border-radius: 0 10px 10px 0;
  }
}