.move-form__list {
  list-style: none;
  padding: 0 12px;
}

.move-form__list-item {
  margin: 7px 0;
}

.move-form__radio {
  visibility: hidden;
}

.move-form__radio:checked + .move-form__label {
  font-weight: bold;
}

.move-form__list_hidden ~ .move-form__list {
  display: none;
}

.move-form__button_hidden {
  visibility: hidden;
}