.gallery-tile__page {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

.gallery-tile {
  margin: 0.66em;
  position: relative;
  height: 300px;
  width: 300px;
}

.gallery-tile__image {
  object-fit: cover;
  object-position: center;
}

.gallery-tile__caption {
  background-color: #13141077;
  bottom: 0;
  color: var(--bg-color);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 0.25em;
  position: absolute;
  width: 100%;
}

.gallery-tile__title {
  display: block;
  flex: 0 0 100%;
  font-size: 1.25em;
  margin: 0.1em 0 0.5em;
  text-align: center;
}

.gallery-tile__meta {
  font-size: 1em;
  font-weight: bold;
}

@media (min-width: 330px) {
  .gallery-tile {
    margin: 1em;
  }
}