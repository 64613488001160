.header {
  background-color: var(--fg-color);
  box-shadow: 0 1px 2px var(--fg-color);
  color: var(--bg-color);
  position: fixed;
  width: 100%;
  z-index: 9999;
}

.header__link {
  color: inherit;
  text-align: center;
  text-decoration: none;
}

.header__nav {
  border-top: 1px solid;
  display: flex;
  flex-direction: column;
  font-size: 6vh;
  height: 0;
  justify-content: space-around;
  opacity: 0;
  transition: height 0.3s;
  visibility: hidden;
}

.header__nav_visible {
  height: calc(100vh - 48px);
  opacity: 1;
  transition: height 1s, opacity 0.8s 0.2s;
  visibility: visible;
}

.header__hamburger {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.header__heading {
  display: flex;
  justify-content: space-between;
  padding: 1em;
}

.header__title {
  font-size: 1.2em;
  margin: 0;
}

.header__nav-link.active {
  text-decoration: underline;
}

.header__nav-link:hover {
  color: var(--sl-color);
}

@media (min-width: 800px) {
  .header {
    display: flex;
    justify-content: space-between;
  }

  .header__nav {
    align-items: center;
    border: none;
    flex-direction: row;
    font-size: 2.5em;
    height: auto;
    opacity: 1;
    visibility: visible;
  }

  .header__hamburger {
    display: none;
  }

  .header__title {
    padding-left: 1em;
    text-align: left;
  }

  .header__nav-link {
    padding: 0 0.3em;
  }
}