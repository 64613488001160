.pager {
  text-align: center;
}

.main__button.pager__button {
  background-color: var(--bg-color);
  border: 1px solid var(--sd-color);
  box-shadow: 0 0 2px var(--sd-color);
  border-radius: 0.5em;
  color: var(--fg-color);
  display: inline-block;
  margin: 0.1em;
  min-width: 2.15em;
  cursor: pointer;
}

.main__button.pager__button:hover {
  background-color: var(--pr-color);
}

.main__button.pager__button_active {
  background-color: var(--sd-color);
  color: var(--sl-color);
  font-weight: bold;
}

.main__button.pager__ellipsis {
  border: none;
  box-shadow: none;
  cursor: auto;
}

.main__button.pager__ellipsis:hover {
  color: var(--fg-color);
  background-color: var(--bg-color);
}