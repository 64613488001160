@import-normalize;
@import url("https://fonts.googleapis.com/css2?family=PT+Sans&display=swap");

:root {
  --fg-color: #131410;
  --sd-color: #703c26;
  --pr-color: #A66A2C;
  --sl-color: #D5AA72;
  --bg-color: #F5F6ED;
}

html {
  font-size: 10px;
}
body {
  color: var(--fg-color);
  background-color: var(--bg-color);
  font-family: "PT Sans", sans-serif;
  margin: 0;
}

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
  overflow-wrap: break-word;
}

.error-boundary {
  align-items: center;
  display: flex;
  font-size: 3vh;
  height: 100vh;
  justify-content: center;
  padding: 1em;
  text-align: center;
}