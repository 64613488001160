.meta {
  border: 1px solid var(--pr-color);
  border-radius: 5px;
  margin: 1em 0;
  padding: 1em;
}

.meta__text-static {
  color: var(--pr-color);
  font-size: 1em;
  margin: 0;
}

.meta__text-dynamic {
  margin-top: 0;
}