.footer {
  background-color: var(--fg-color);
  box-shadow: 0 -1px 2px var(--fg-color);
  color: var(--bg-color);
  font-size: 1.5em;
  padding: 1em;
  text-align: center;
}

.footer__text {
  margin: 0;
}

.footer__link {
  color: var(--pr-color);
  display: block;
  margin: 1em;
}