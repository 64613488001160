.comments__list {
  list-style: none;
  padding: 0;
  margin: 2em 0;
}

.comments__item {
  border-top: 1px solid var(--pr-color);
  margin: 1em 0;
  padding: 0 1em;
}

.comments__head {
  color: var(--sd-color);
}

.main__button.comments__button {
  border-radius: 5px;
  height: 1.5em;
  margin-left: 1em;
  padding: 0 0.5em;
}

.comments__title {
  font-weight: bold;
}