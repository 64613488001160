.home__fast-links {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: auto;
  width: 200px;
}

@media (max-width: 499px) {
  .home__fast-links a {
    margin-right: 0;
  }
}

@media (min-width: 500px) {
  .home__fast-links {
    flex-direction: row;
    max-width: 900px;
    width: auto;
  }
}