.text-tile {
  border-bottom: 2px solid var(--pr-color);
  margin: 0 auto 1em;
  max-width: 800px;
  padding: 0.75em;
}

.text-tile__meta {
  color: var(--pr-color);
  display: flex;
  justify-content: space-between;
}

.text-tile__title {
  text-align: center;
}