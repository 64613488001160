.App {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.main {
  font-size: 1.5em;
  line-height: 1.25em;
  padding: 4em 1em 2em;
}

.main__button {
  background-color: var(--sd-color);
  border: none;
  border-radius: 10px;
  color: var(--sl-color);
  cursor: pointer;
  font-size: inherit;
  font-weight: bold;
  line-height: 1em;
  padding: 0.5em;
}

.main__button:hover {
  color: var(--bg-color);
}

.main__button_unstretch {
  align-self: center;
}

.main__link {
  color: var(--sd-color);
}

.main__link:hover {
  color: var(--pr-color);
}

.main__loading {
  text-align: center;
  width: 100%;
}

.main__text {
  margin: 15px auto;
  max-width: 900px;
  text-align: justify;
}

.main__link_as-button {
  display: inline-block;
  line-height: 1em;
  padding: 0.5em;
  text-align: center;
  text-decoration: none;
}

.main__heading {
  text-align: center;
}

.main__button_many {
  margin-right: 1em;
  margin-bottom: 1em;
}

.main__important {
  background-color: #FF000011;
  border: 3px solid #FF000077;
  padding: 10px;
  text-align: center;
}

@media (min-width: 330px) {
  .main {
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
}