.tags {
  margin: 0;
  padding: 0;
}

.tags__item {
  background-color: var(--pr-color);
  border: 1px solid var(--sd-color);
  display: inline-block;
  margin: 0.5rem;
  max-width: 100%;
  word-wrap: break-word;
}

.tags__link {
  color: var(--bg-color);
  display: block;
  padding: 0.5rem;
  text-decoration: none;
}

.tags__link::before {
  content: "⏺ ";
}