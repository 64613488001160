.form {
  border: 2px solid var(--pr-color);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  font-size: 1.5em;
  line-height: 1.5em;
  margin: 1em auto;
  max-width: 800px;
  padding: 0.75em;
}

.form__input {
  background-color: var(--bg-color);
  border: 1px solid var(--pr-color);
  color: var(--fg-color);
  font-size: inherit;
  margin-bottom: 0.5em;
  padding: 0.25em;
}

.form__input:focus {
  outline: 2px solid var(--pr-color);
}

.form__input:disabled {
  color: var(--pr-color);
}

.form__message {
  background-color: var(--sl-color);
  border: 1px solid var(--pr-color);
  border-radius: 10px;
  padding: 0.5rem;
  text-align: center;
}

.form__submit {
  align-self: center;
  margin-top: 0.5em;
}

.form__input-button-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

@media (min-width: 600px) {
  .form__input-button-group {
    flex-direction: row;
  }

  .form__input_ibg {
    flex: 1;
    margin-bottom: 0;
    margin-right: 1em;
  }
}

@media (min-width: 800px) {
  .form_gallery {
    font-size: 1em;
  }
}