.admin-users__table {
  border-collapse: collapse;
  margin: 0 auto;
}

.admin-users__table td {
  border: 1px solid var(--sd-color);
  padding: 5px;
}

.admin-users__button {
  margin-right: 5px;
}

.admin-users__button:last-of-type {
  margin-right: 0;
}

.admin-users__online {
  text-align: center;
}