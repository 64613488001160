.profile__fieldset {
  border: none;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
  padding: 0;
}

.profile__button {
  margin: 0 0.1em 1em;
}

.profile__buttons {
  display: flex;
  justify-content: space-around;
}