.photo-form__image-preview {
  display: flex;
  position: relative;
}

.photo-form__watermark {
  position: absolute;
  z-index: 1;
}

.photo-form__remove {
  justify-content: center;
  display: flex;
  margin: 0;
  padding: 0;
  width: 100%;
}